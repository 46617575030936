const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://mbio09oi7c.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_API_URL: 'https://1ax8zbf5kh.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.europe.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.europe.forwoodsafety.com',
    WEBSOCKET: 'wss://a1l7hepeqj.execute-api.eu-west-1.amazonaws.com/prod'
  },
};

export default config;